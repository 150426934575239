/* css */
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
@import url("https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Merriweather+Sans");
.ant-layout-sider-light {
  top: 0px;
  left: 0px;
  z-index: 111;
  overflow: hidden !important;
  box-shadow: rgb(0 0 0 / 6%) 0px 5px 5px -3px, rgb(0 0 0 / 4%) 0px 8px 10px 1px,
    rgb(0 0 0 / 4%) 0px 3px 14px 2px !important;
  color: rgb(255, 255, 255) !important;
  transition: all 150ms ease-in-out 0s !important;
}
.ant-menu {
  background: transparent !important;

  color: rgba(44, 56, 74, 0.681) !important;
  overflow-y: auto !important;
  height: 100vh;
}
.ant-menu-item-selected {
  background: #3d70cd !important;
  color: #ffffff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
  background: #3d70cd !important;
}
.ant-menu-item-selected a {
  color: #5046e5 !important;
}

.ant-menu-item-selected a:hover,
.ant-menu-item-selected:hover,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-item a:hover {
  color: rgb(255, 255, 255) !important;
}

.ant-menu-item:hover,
.ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-selected,
.ant-menu-submenu-arrow,
.ant-menu-submenu-title:hover {
  background: #3d70cd !important;
}

.ant-menu-inline .ant-menu-item-selected::after {
  border-right: none !important;
}
.ant-menu-inline,
.ant-menu-vertical {
  border-right: none !important;
}
.sider-links:hover {
  color: rgb(255, 255, 255) !important;

  text-decoration: none;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  margin: 0 0 0 0px !important;
  width: 258.5px !important;

  border-radius: 4px !important;
  height: 44px !important;
  white-space: pre !important;
  margin-bottom: 8px !important;
  text-decoration: none !important;
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
  transition: all 150ms ease-in 0s !important;
}
.brand-name {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 11px 30px;
  height: 64px;
  margin-left: -48px;
}
.brand-logo {
  gap: 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.menu-subhead {
  white-space: normal;
  font-size: 12.5px;
  margin-top: 25px;
  margin-left: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #202020b3;
}
.trigger {
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  color: #fff !important;
  padding: 15px;
  margin: 0 0 0 10px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
}
.trigger:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: #fff !important;
  border-radius: 50% !important;
  overflow: visible !important;
}

.admin-dash Header {
  top: 0;
  z-index: 96;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 4%),
    0px 3px 14px 2px rgb(0 0 0 / 4%) !important;
  height: 64px;
  position: sticky !important;
}
.dropdown-toggle::after {
  display: none !important;
}
.avatar-user-details {
  border-radius: 24px !important;
  padding: 4px !important;
  /* display: inline-block !important; */
  color: #000000 !important;
  float: right !important;
  /* margin: 10px 0 0 auto; */
  margin: 5px 0 0 0;
}
.avatar-user-details:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.avatar-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.avatar-box span {
  margin: 0 6px;
}
.avatar-box .anticon svg {
  margin: -6px 0 0 0;
  font-size: 22px !important;
}
.avatar-name {
  line-height: 1.5;
  text-transform: none;
  white-space: normal;
}
.avatar-img {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  /* width: 40px;
  height: 40px; */
  font-family: "Lato", sans-serif;
  font-size: 1.25rem;
  /* line-height: 1; */
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.menu-position {
  position: fixed !important;
}
/* .ant-layout-footer {
  color: rgb(255, 255, 255) !important;
  background-color: #5046e5 !important;
} */

.ant-layout-footer {
  color: rgb(255, 255, 255) !important;
  background-color: #1677ff !important;
  /* background-color: #007dfe !important; */
  width: 100%;
  text-align: center;
  padding: 18px !important;
  margin: 20px 0 0 0 !important;
}
.ant-dropdown-menu-item:hover {
  margin: 0 5px 0 5px !important;
  border-radius: 5px !important;
}
.container-dash-g {
  overflow: hidden auto !important;
  height: 420px;
  margin: -20px -20px -32px -20px;
}
.name-g {
  font-size: 1.5rem;
  font-weight: bolder;
  text-transform: capitalize;
  margin-bottom: 2px;
}
.graph-div {
  overflow: hidden;
  height: 290px;
  margin: 0;
}
.dash-graph-line {
  padding: 28px 32px 56px !important;
  background: #fafafa !important;
}
.div-dash {
  position: relative;
  top: -25px;
}
/*COLLAPSED TOOGLE START*/
.display-switch,
.collapsed-switch-visible {
  display: none;
}
.ant-switch-checked {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-switch-checked:focus {
  box-shadow: none !important;
}
.ant-switch-handle:before {
  background-color: #fff !important;
}
.toogle-off {
  z-index: 9999 !important;
  top: 20px !important;
  position: fixed !important;
  left: 211px !important;
}
.toogle-on {
  z-index: 9999 !important;
  top: 5px !important;
  position: fixed !important;
  left: 23px !important;
}
.ant-layout-sider-collapsed .app-name {
  display: none !important;
  color: #447de5 !important;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: 80px !important;
}
.ant-layout-sider-collapsed .brand-name {
  margin: 0px 0 0 -5px;
}
.ant-layout-sider-collapsed .menu-subhead {
  white-space: normal;
  font-size: 12.5px;
  margin-top: 25px;
  margin-left: 6px;
  /* margin-left: 10px; */
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #202020b3;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  margin: 0 0 0 0 !important;
  /* margin: 0 0 0 5px !important; */
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  width: 0px !important;
  margin: 5px 0px 0 7.5px !important;
  border-radius: 5px !important;
}
/*COLLAPSED TOOGLE END*/

.admin-dash {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.site-layout .site-layout-background {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-feature-settings: "cv02", "cv03", "cv04", "cv11" !important;
  background: rgb(255 255 255 / 1);
  display: flex !important;
}

/*************************NAVBAR Starts*************************/
.navbar {
  display: flex;
  align-items: center;
  width: 100vw;
  background-color: #fff;
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 0px 70px;
  background-color: #fff;
}
.navbar-logo {
  color: #ff3f34;
  text-decoration: none;
  font-size: 25px;
  padding: 0px 20px;
}
.navbar-links {
  list-style-type: none;
  display: flex;
}
.navbar-links li a {
  display: block;
  text-decoration: none;
  color: #444;
  /* padding: 20px 20px; */
  padding: 0 10px;
  font-weight: 500;
  transition: 0.4s all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-links li.navbar-dropdown {
  position: relative;
  /* top: -20px; */
}

.navbar-links li.navbar-dropdown:hover .dropdown {
  /* visibility: visible; */
  display: flow-root;
  opacity: 1;
  transform: translateY(0px);
}

.navbar-links li.navbar-dropdown .dropdown {
  /* visibility: hidden; */
  display: none;
  opacity: 0;
  position: absolute;
  padding: 20px 0;
  top: 100%;
  transform: translateY(50px);
  left: 0;
  width: 250px;
  background-color: #fff;
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 111;
  transition: 0.4s all;
}
.navbar-links li.navbar-dropdown .dropdown a {
  /* padding-top: 10px;
  padding-bottom: 10px; */
  font-weight: 400;
  margin: -20px 0px -20px 0;
}
.navbar-dropdown .dropdown a:hover {
  padding-left: 30px;
}
.navbar-links li a:hover {
  color: #4143e5;
  background-color: #f6f6f6;
}

/*************************NAVBAR ENDs*************************/
/*************************SCROLLBAR Starts*************************/

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9fa0a1;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666666;
}
/*************************SCROLLBAR ENDS*************************/

/********************Media Responsive START********************/
@media (min-width: 1501px) {
  .trigger-phone {
    display: none !important;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .trigger-phone {
    display: none !important;
  }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .trigger-phone {
    display: none !important;
  }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991.98px) {
  .trigger-phone {
    display: none !important;
  }
  .header-nav {
    display: none;
  }
  .navbar-links {
    display: table-header-group;
  }
  .navbar-links li.navbar-dropdown .dropdown a {
    display: inline-flex;
  }
}

/* small mobile :320px. */
@media (min-width: 576px) and (max-width: 767.98px) {
  .container-dash-g {
    overflow: hidden auto !important;
    height: 420px;
    margin: -30px -12px;
  }

  .ant-layout-sider {
    display: none;
  }
  .trigger {
    display: none !important;
  }
  .trigger-phone {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    color: #626262 !important;
    padding: 15px;
    margin: 0 0 0 10px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
  }
  .trigger-phone:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: #000000 !important;
    border-radius: 50% !important;
    overflow: visible !important;
  }
  .header-nav {
    display: none;
  }
  .navbar-links {
    display: table-header-group;
  }
  .navbar-links li.navbar-dropdown .dropdown a {
    display: inline-flex;
  }
}

/* Large Mobile :480px. */
@media only screen and (max-width: 575.98px) {
  .container-dash-g {
    overflow: hidden auto !important;
    height: 420px;
    margin: -30px -12px;
  }

  .ant-layout-sider {
    display: none;
  }
  .trigger {
    display: none !important;
  }
  .ant-row {
    margin: 0 0 0 0 !important;
  }
  .trigger-phone {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    color: #626262 !important;
    padding: 0px;
    margin: 0 0 0 10px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
  }
  .trigger-phone:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: #000000 !important;
    border-radius: 50% !important;
    overflow: visible !important;
  }

  .Search-admin {
    margin: 0 0 0 20px;
  }
  .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    width: 301px !important;
  }
  .ant-layout-content {
    /* margin: 20px 0px !important; */
    margin: 0px 15px !important;
    padding: 10px 5px !important;
  }
  .header-nav {
    display: none;
  }
  .navbar-links {
    display: table-header-group;
  }
  .navbar-links li.navbar-dropdown .dropdown a {
    display: inline-flex;
  }
}

/********************Media Responsive END********************/

/* -------------------New UI--------------- */

.side-header {
  background: #3d70cd;
  margin-bottom: 4px;
}
.side-header-small {
  background: #5046e5;

  margin: -23px -24px 15px -24px;
}

.logo {
  height: 40px;
  width: 45px;
  margin-top: 5px;
  margin-left: 33px !important;
  margin-right: 2px;
  border-radius: 50%;
}

/********************Devops******************/

/* .statistics-card .card {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  background: #fff !important;
  padding: 10px 20px !important;
} */

.pages-header {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 350;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
}

.ant-btn-text {
  color: #000000 !important;
}
.ant-card-bordered {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}

.ant-table-thead > tr > th {
  color: #9f9f9fd9 !important;
  background: #ffffff !important ;
}

.site-layout {
  background: #fafafa !important;
  letter-spacing: 0.49px !important;
}

.container-dash-g {
  overflow: hidden auto !important;
  height: 420px;
  margin: -20px -20px -32px -20px;
}

.statistics-card .cloud-charts {
  padding: 20px 0 0 0 !important;
}

.ant-breadcrumb a {
  color: #5046e5 !important;
  margin: -4px 0 0 0 !important;
}
.ant-breadcrumb a:hover {
  color: #473be7 !important;
}

.orgbutton {
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  /* float: right !important; */
}

.ok-modal {
  background: #5046e5 !important;
  border: 1px #5046e5 !important;
  font-size: 14px;
  color: #fff !important;
  border-radius: 5px;
  letter-spacing: 1.5px !important;
  line-height: 20px;
  font-weight: 550;
}

.ok-modal:hover {
  background: #5d52fa !important;
}
.close-modal {
  background-color: #f1c202 !important;
  border-color: #f1c202 !important;
  color: white !important;
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 1.5px !important;
  line-height: 20px;
  font-weight: 550;

  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.close-modal:hover {
  background-color: #f1c202 !important;
  border-color: #f1c202 !important;
  color: white !important;
}

.f-right {
  float: right;
}

.ant-modal-body {
  margin: 0px 0px 25px 0 !important;
}

/**** user table **/
.usertable {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.userdetailcontainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

/*** float label css ***/

.tablecustom.ant-table-wrapper .ant-table-tbody > tr > th,
.tablecustom.ant-table-wrapper .ant-table-tbody > tr > td {
  border-right: 1px solid #dcdcdc !important;
  border-bottom: 1px solid #dcdcdc !important;
}

.tablecustom.ant-table-wrapper table {
  border-left: 1px solid #dcdcdc !important;
}

/* .tablecustom.ant-table-tbody > tr > td {
  padding: 8px 8px !important;
} */

.tablecustom.ant-table-wrapper .ant-table-thead > tr > th,
.tablecustom.ant-table-wrapper .ant-table-thead > tr > td {
  border-bottom: 1px solid #dcdcdc !important;
  border-right: 1px solid #dcdcdc !important;
  border-top: 1px solid #dcdcdc !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important;
  border-color: blue !important;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: blue !important;
}

.tablecustom.ant-table-wrapper .ant-table-thead > tr > th,
.tablecustom.ant-table-wrapper .ant-table-tbody > tr > th,
.tablecustom.ant-table-wrapper tfoot > tr > th {
  padding: 8px 8px !important;
}

.tablecustom.ant-table-wrapper .ant-table-thead > tr > th,
.tablecustom.ant-table-wrapper .ant-table-tbody > tr > th,
.tablecustom.ant-table-wrapper .ant-table-tbody,
.tablecustom.ant-table-wrapper tfoot > tr > th,
.tablecustom.ant-table-wrapper tfoot > tr > td {
  border-top: 1px solid #dcdcdc !important;
  border-right: 1px solid #dcdcdc !important;
}

.TabsLoading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.side-header-small {
  background: #5046e5;
  margin: -23px -24px 15px -24px;
}

.masterTable .ant-table-header {
  display: none !important;
}

.masterTable .ant-table-thead {
  display: none !important;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #ffffff !important;
}

.tablecustom.ant-table-measure-row {
  padding: 0px 0px !important;
}
.tablecustom .ant-table-thead > tr > th,
.tablecustom .ant-table-tbody > tr > td,
.tablecustom .ant-table tfoot > tr > th,
.tablecustom .ant-table tfoot > tr > td {
  /* position: relative; */
  padding: 0px 20px !important;
}

/* .content {
  border: 0.5px solid rgb(214, 214, 214);
  padding: 0.5em;
  white-space: pre-wrap;
  overflow-y: scroll;
}

.content {
  scrollbar-width: none;
}

.content::-webkit-scrollbar {
  display: none;
} */

.ant-steps-small .ant-steps-item-container {
  margin-left: 0px !important;
  padding: 0px 30px 5px 10px !important;
}
.ant-steps-item-container {
  margin-left: 0px !important;
  padding: 0px 30px 5px 10px !important;
}

/* .ant-steps-item.ant-steps-item-process {
  padding-right: 100px !important;
} */

.infoicon.anticon {
  margin: 5px 0px 0 5px !important;
}

.step.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: none !important;
  border: none !important;
}

.step.ant-steps-small .ant-steps-item-icon {
  width: 0px !important;
  height: 0px !important;
  margin: 0 0 0 0 !important;
  font-size: 0px !important;
  line-height: 0px !important;
  text-align: center !important;
  border-radius: 0px !important;
}

.step .ant-steps-item-wait .ant-steps-item-icon {
  border-color: rgb(0 0 0 / 0%) !important;
}

.step
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #eb2f9600 !important;
}

.step .ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff0;
  border-color: #1890ff00 !important;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item:first-child {
  padding: 0 70px 0 0 !important;
}
.add-button {
  float: right !important;
}
.btn-admin {
  border-color: #5046e5 !important;
  background: #5046e5 !important;
}
.drag-area {
  border: 1px dashed #3c3c3c;
  height: 140px;
  width: 130px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.drag-area:hover {
  border: 1px dashed #5046e5;
}
.drag-area-img {
  width: 130px;
  margin: 0px 0px 0px 110px;
}

.drag-area.active {
  border: 2px solid #fff;
}
.drag-area .icon {
  font-size: 100px;
  color: #fff;
}
.drag-area header {
  font-size: 15px;
  font-weight: 500;
  color: #3c3c3c;

  margin: 36px 0px 0 0;
}
.details {
  display: inline-flex !important;
}
.count-cards .details .anticon svg {
  /* opacity: 0.6 !important; */
  font-size: 44px !important;
  color: #fff !important;
}
.count-cards .buttons .anticon svg {
  /* opacity: 0.6 !important; */
  font-size: large;
  margin: -4px 0 0 0;
}
.count-cards .card {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  display: flex !important;
}
.count-cards .card:hover {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%), 0px 1px 8px 0px rgb(0 0 0 / 22%) !important;
  transition: 0.3s linear all;
}
.dash-chart-count .card {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  display: flex !important;
}
.count-cards .card-body {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important;
  /* padding: 15px !important; */
  padding: 24px 15px !important;
  background: #fff !important;
  /* height: 80px !important; */
}
.count-cards .count .name-cloud {
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 550 !important;
  line-height: 1.5 !important;
  text-transform: none !important;
  white-space: normal !important;
}
.count-cards .count h6 {
  margin: 0 !important;
  margin-top: 4px !important;
  font-size: 14px !important;
  font-weight: 550 !important;
  color: #1976d2 !important;
}
.count-cards .card .buttons {
  float: right !important;
  margin: 4px 0 0 0 !important;
  /* padding: 0px !important; */
}
.count-cards .card .buttons .ant-btn-lg {
  padding: 6.4px 10px !important;
}
.count-cards .card .buttons:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(52, 49, 76, 1) !important;
  border-radius: 50% !important;
  overflow: visible !important;
}
.count-cards .card-body1 {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important; /* box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),  0px 1px 8px 0px rgb(0 0 0 / 4%) !important; */
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important; /* padding: 15px !important; */
  padding: 24px 15px !important;
  background-image: linear-gradient(
    45deg,
    var(--cui-danger-start, #89ec8b 0),
    var(--cui-danger-stop, #2fcd5c 100%)
  );
  /* background-image: linear-gradient(
    45deg,
    var(--cui-primary-start, #86bde7 0),
    var(--cui-primary-stop, #0078d4 100%)
  ); */
  /* height: 80px !important; */
}
.count-cards .card-body2 {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important; /* box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),  0px 1px 8px 0px rgb(0 0 0 / 4%) !important; */
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important; /* padding: 15px !important; */
  padding: 24px 15px !important;
  /* background-image: linear-gradient(
    45deg,
    var(--cui-danger-start, #ec89a7 0),
    var(--cui-danger-stop, #d73263 100%)
  ); */
  background-image: linear-gradient(
    45deg,
    var(--cui-primary-start, #86bde7 0),
    var(--cui-primary-stop, #0078d4 100%)
  );
  /* height: 80px !important; */
}

.count-cards .card-body3 {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important;
  padding: 24px 15px !important;
  background-image: linear-gradient(
    45deg,
    var(--cui-warning-start, #ebc088 0),
    var(--cui-warning-stop, #f08804 100%)
  );
}

/* ChatBotModal.css */
.chat-bot-modal {
  position: sticky;
  width: 450px;
  height: 406px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.chat-bot-modal-ask-hr {
  width: 30%;
  height: 85vh;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.chatbot-text {
  position: fixed;

  padding: 10px;
  cursor: pointer;
  right: 100px;
  bottom: 100px;
}

.chatbot-icon {
  position: fixed;

  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  right: 20px;
  bottom: 90px;

  box-shadow: 1px 1px 1px 1px grey;

  border: none;
  outline: none;
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-image: linear-gradient(90deg, #7895cb, #a0bfe0, #c5dff8);
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* background-color: #faf7f0; */
  background-image: url("./assests/img/convoBg.png");
}
.messages-user-manual {
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

.message {
  padding: 8px;
  margin: 5px;
  border-radius: 5px;
  max-width: 70%;
  word-break: break-word;
}
.user-image,
.api-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.user {
  background-color: #447de5;
  color: white;
  align-self: flex-end;
}

.input-container-user-manual {
  display: flex;
  padding: 5px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 25px;
}

.input-user-manual {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
  background: none;
}
.input-user-manual:focus {
  border: none;
  outline: none;
}

.api {
  align-self: flex-start;
  /* background-color: #e2e2e2; */
  background-color: #ade1fa;
}
.error-message {
  align-self: flex-start;
  background-color: red;
  color: white;
}
.block-input {
  cursor: not-allowed;
}

.input-container {
  display: flex;
  padding: 5px;
  background-color: #c5dff8;
}

input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
  background: none;
}
input:focus {
  border: none;
  outline: none;
}
.close-button {
  border: none;
  outline: none;
  background-color: white;
  font-size: 17px;
  margin-right: 6px;
}

/* .send-icon {
  padding: 8px 15px;
  border: none;
  background: none;
  color: white !important;
  outline: none;
  cursor: pointer;
} */
.chatbot-logo {
  margin-left: 0 !important;
  width: 40px;
  height: 40px;
}
.upload-file {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-content {
  width: 50vw;
}
.text-header {
  display: flex;
  justify-content: center;
}
.additional-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.data-card .ant-card {
  /* background-color: #0078d4 !important; */
  /* color: red !important; */
  background-color: #f8fafe !important;
  /* background-image: linear-gradient(90deg, #c5dff8); */
  border-radius: 10px;
}
.navigate-button {
  border: none;
  outline: none;
}
.main_header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-container {
  height: 84%;
  /* border: 4px solid gray; */
  margin: 20px;
  border-radius: 20px;
  /* box-shadow: 0px 0px 4px 4px grey; */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
  padding: 20px;
  margin-bottom: 40px;
}
.app-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.plus-icon:hover {
  cursor: pointer;
}
.minus-icon:hover {
  cursor: pointer;
}

/* .small-generate-button {
  margin: 20px;
  width: 36%;
  border-radius: 10px;
} */
.generate-button {
  margin: 20px;
  width: 96%;
}
.ip-button {
  display: flex;
  justify-content: space-between;
}
.main-container {
  /* background-color: #b2b2e1; */
  margin: 0;
}
.header-ai {
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #85cdfd;
}
.heading-ai {
  padding: 20px;
}
.app-tab {
  margin: 20px;
}
.content-automation-option {
  border: 3px solid #85cdfd;
  padding: 20px;
  border-radius: 10px;
  max-height: 340px;
  overflow-y: auto;
}
.choose-option-text {
  font-size: 14px;
}
.choose-option-content {
  display: flex;
}
.choose-one-radio {
  border: 1.5px solid #85cdfd;
  height: 40px;
  width: 400px;
  display: flex;
  align-items: center;
  padding: 20px;
  /* color: white; */
  font-size: 16px;
  border-radius: 10px;
}
.choose-two-radio {
  border: 1.5px solid #85cdfd;
  height: 40px;
  width: 400px;
  display: flex;
  align-items: center;
  padding: 20px;
  /* color: white; */
  font-size: 16px;
  border-radius: 10px;
  margin-left: 10px;
}
.language-radio {
  border: 1.5px solid #85cdfd;
  height: 40px;
  /* padding: 20px; */
  font-size: 16px;
  border-radius: 10px;
  margin-left: 10px;
}
.run-button {
  border: 3px solid #85cdfd;
  height: 40px;
  /* padding: 20px; */
  color: black;
  font-size: 16px;
  border-radius: 10px;
  /* margin-left: 10px; */
}
.automate-container {
  max-height: 350px;
  border: 3px solid #85cdfd;
  margin-top: 20px;
  padding-top: 3px;
}

.sub-automate-container {
  border: 3px solid #85cdfd;

  border-radius: 32px 0 32px 0px;
  padding: 40px;
  padding-top: 20px;
  font-size: large;
  margin-bottom: 20px;
}
.chatbot-conatiner {
  border: 3px solid #85cdfd;
  margin-top: 40px;
  border-radius: 10px;
}
.chatbot-text-conatiner {
  margin: 20px;
  height: 50px;
  border-radius: 10px;
}
.restart-container {
  border: 3px solid #85cdfd;
  margin-top: 30px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-text {
  border: 1.5px solid #85cdfd;

  /* border: none; */
  height: 50px;
}
.language-dropdown {
  border: 3px solid #85cdfd;
  height: 60px;
  margin-top: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.language-dropdown:hover {
  cursor: pointer;
}
.config-container {
  height: 500px;
  border: 1px solid grey;
  margin: 40px;
  border: 3px solid #85cdfd;
  border-radius: 20px;
  box-shadow: 0 0 4px gray;
}
.videofilename {
  display: flex;

  justify-content: space-around;
}
.language-dropdown.dropdown-parent {
  width: 100%;
}

@media (max-width: 575px) {
  .dropdown-child {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .dropdown-child {
    width: 200px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .dropdown-child {
    width: 250px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .dropdown-child {
    width: 300px;
  }
}

@media (min-width: 1200px) {
  .dropdown-child {
    width: 350px;
  }
}
.option-change {
  background-color: #e3f6ff;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 20px;
  /* color: white; */
  font-size: 16px;
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 20px;
  margin: 30px;
}
.option-change-ques {
  border: 3px solid #85cdfd;
  display: flex;
  align-items: center;
  padding: 20px;
  /* color: white; */
  font-size: 16px;
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 20px;
  margin: 30px;
}
.query-text {
  border: 1.5px solid #85cdfd;
  height: 40px;
  padding-left: 20px;
  border-radius: 10px;
  padding-top: 5px;
  margin: 20px;
  font-size: 16px;
}
.query-response {
  border: 1.5px solid #85cdfd;
  height: 200px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  border-radius: 10px;
  margin-top: 20px;
  margin: 20px;
  overflow-y: auto;
  padding: 20px;
  text-justify: auto;
  font-size: 16px;
}
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
}
.config-save-button:hover {
  color: black;
}
.video-download-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
  background: #447de5;
}
.sider-menu .side-bar-ul {
  display: flex;
  flex-direction: column;
}
.sider-menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 !important;
  padding-left: 38px !important;
  color: white;
}
.sider-menu .anticon {
  color: white !important;
}
.sider-menu .ant-layout-content {
  flex: auto;
  min-height: 0;
  background: #e7ebf7 !important;
  margin: 0 !important;
  padding: 30px 10px !important;
}
.dataCard.ant-card-bordered {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}
.menubar .ant-menu-item a {
  color: white !important;
}

.chat-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 300px;
  padding: 16px;
}
.sender {
  background-color: #dcf8c6;
  align-self: flex-end;
  padding: 8px;
  margin: 5px;
  border-radius: 5px;
  max-width: 70%;
  word-break: break-word;
}

.receiver {
  background-color: #bbcae7;
  align-self: flex-start;
  padding: 8px;
  margin: 5px;
  border-radius: 5px;
  max-width: 70%;
  word-break: break-word;
}
.messagebody {
  padding: 8px;
  margin: 5px;
  border-radius: 5px;
  max-width: 70%;
  word-break: break-word;
}

.sales-messages-box {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* background-color: #faf7f0; */
  background-image: url("./assests/img/salesgptbackground.png");
  background-repeat: repeat;
}
.sales-gpt-modal {
  /* position: sticky;
  bottom: 75px;
  right: 40px; */
  /* width: 100%; */
  height: 390px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  /* padding: 0;
  margin: 0; */
}

.ask-hr-chatbot {
  height: 90vh;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

@media (max-width: 575px) {
  .head-app-about {
    display: none;
  }
}

.pose-mimic {
  .row {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
    border-radius: 6px;
  }
  .desney-pose-button {
    margin: 20px;
    /* padding: 10px; */
    border-radius: 6px;
  }
  .charactor-img {
    width: 300px;
    height: 300px;
    border: 1px solid;
    border-radius: 100%;
    margin: 0 auto;
  }
  .charactor-img1 {
    background-image: url("./assests/img/posemimic.png");
    background-size: cover;
  }
  .charactor-img2 {
    background-image: url("./assests/img/realstic.png");
    background-size: cover;
  }
  .pose-heading {
    text-align: center;
    padding: 40px 20px;
  }
  .box1 {
    border-right: 1px solid;
    margin: 40px 0;
  }
  .box2 {
    margin: 40px 0;
  }
  .App {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5vw;
    background-color: #282c34;
  }

  .Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0 10px;
  }
  .webcam-container {
    text-align: center;
  }
  img {
    height: 400px;
    width: 400px;
    object-fit: cover;
  }

  button {
    background-color: crimson;
    color: #fff;
    margin-top: 10px;
    padding: 10px 40px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }
}

.category-card .ant-card-body {
  padding: 10px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.ui-container {
  /* min-height: calc(100vh - 152px) !important; */
  min-height: calc(100vh) !important;
}

.drum-pad-container {
  /* .container {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ea910b;
  font-family: "Audiowide", monospace;
} */

  .machine {
    max-width: 800px;
    width: 100%;
    display: flex;
    background: #111;
    margin: 50px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 5px 5px 8px 3px #333;
  }

  .pads {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
  }

  /* .outer-drum-pad {
  width: 120px;
  height: 120px;
  background: #1ec8ce;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
} */
  .drum-pads {
    display: flex;
  }
  .drum-pad {
    width: 150px;
    height: 150px;
    background: radial-gradient(#333, #070707);
    border: 3px solid blue;
    /* border-radius: 50%; */
    text-align: center;
    vertical-align: middle;
    line-height: 120px;
    font-size: 24px;
    color: rgba(255, 255, 255, 0);
    margin: 10px;
    cursor: pointer;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  #drum-machine {
    padding: 0 20px;
  }
  /* .side-panel {
  height: 100%;
  width: 40%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  text-align: center;
  font-size: 20px;
} */

  /* .label {
  color: white;
  font-style: italic;
  text-align: center;
  font-size: 24px;
} */

  .display {
    width: 200px;
    height: 20px;
    margin: 20px auto;
    padding: 5px;
    font-size: 20px;
    background: #1ec8ce;
    border: 2px solid #333;
    border-radius: 3px;
    color: #111;
  }

  /* .speakers {
  width: 100%;
  padding: 20px;
  hr {
    background: #000;
    width: 90%;
    height: 9px;
    margin: 15px 0;
    border: none;
  }
} */

  button {
    background: green;
    width: 40px;
    height: 20px;
    margin: 10px 0 5px;
    cursor: pointer;
  }

  input {
    margin: 10px;
  }
}

.music-tab {
  margin: 20px;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #1890ff;

  .ant-tabs-tab-btn {
    border-radius: 6px;
    border: 1px solid;
    padding: 5px 88px;
  }
  /* .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active{
    border: 1px solid;
    border-radius: 6px;
  } */
  .music-button {
    padding: 5px 64px;
    margin: 2px;
    border-radius: 6px;
  }
  .music-play-button {
    border: 1px solid;
    height: 180px;
    width: 176px;
    border-radius: 54px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .music-play-image {
    background-image: url("./assests/img/play.png");
    background-size: cover;
  }
}

/* Music play button with progress bar */

.music-play-container {
  position: relative;

  display: inline-block;
}

.music-button {
  padding: 5px 64px;
  margin: 2px;
  border-radius: 6px;
}
.music-play-button {
  border: 1px solid;
  height: 180px;
  width: 176px;
  border-radius: 54px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.music-play-image {
  background-image: url("./assests/img/play.png");
  background-size: cover;
}

.music-play-button {
  padding: 10px 20px;

  background-color: #00bcd4;

  color: #fff;

  border: none;

  outline: none;

  cursor: pointer;

  transition: background-color 0.3s;
}

.music-play-button.playing {
  background-color: #ff0000; /* Change the color when playing if needed */
}

.progress-bar {
  position: absolute;

  bottom: 0;

  left: 0;

  width: 0;

  height: 4px;

  background-color: #00bcd4; /* Progress bar color */

  transition: width 0.3s;
  margin-bottom: 100px;
  position: absolute;
  bottom: 57px;
  width: 400px;
  right: 0;
  border: 1px solid red;
  z-index: 48;
}

/* Adjust the progress bar appearance as needed */

.progress-fill {
  height: 100%;
}
p {
  font-size: 14px;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking-cursor {
  font-weight: bold;
  font-size: 24px;
  margin-left: 10px;
  color: black;
  animation: blink 1s step-start infinite;
}

.like-dislike-conatiner {
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f0ece4;
  margin-top: 5px;
}

.employee-assistance-container {
  height: 100%;
  background-image: url("https://png.pngtree.com/background/20220721/original/pngtree-human-resources-and-customer-relationship-management-picture-image_1703645.jpg");
}
